export const homeObjOne = {
    id: 'ab',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Hello, I am...',
    headline: 'Front-end web developer ',
    description: 'Currently studying at Thompson Rivers University. Want to become a professional full-stack web developer. Currently looking for good co-op opportunities.',
    buttonLabel: 'My Resume',
    imgStart: false,
    img: require('../../images/img-1.png'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:false,
}

export const homeObjTwo = {
    id: 'pr',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Project #1',
    headline: 'Amazon Clone',
    description: 'Amazon clone built using ReactJS, HTML, CSS, React Context API, Firebase Auth, Firebase Database. User can Register, Login, Logout, view cart, add and delete products from the cart.',
    buttonLabel: 'Visit Project',
    projectLink: 'https://xenon-axe-234500.web.app',
    imgStart: true,
    img: require('../../images/img-amazon.jpg'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:false,
}

export const homeObjThree = {
    id: 'pr',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Project #2',
    headline: 'Youtube Clone',
    description: 'Youtube clone built with React and Pure CSS. No backend yet, just front end. Built mostly with CSS Flexbox. Try resizing the window.',
    buttonLabel: 'Visit Project',
    projectLink: 'https://clone-hryhorii.web.app',
    imgStart: false,
    img: require('../../images/img-youtube.jpg'),
    alt:'Car',
    dark:false,
    primary:false,
    darkText:true,
}

export const homeObjFour = {
    id: 'pr-2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Project #3',
    headline: 'Virtual Guitar',
    description: 'This app was built using HTML5, CSS3(SCSS), React.js, HTML5 Canvas and Note.js. All the samples were recorded by me.',
    buttonLabel: 'Visit Project',
    projectLink: 'https://rakosha.github.io/Virtual-Guitar-Project/',
    imgStart: true,
    img: require('../../images/virtualGuitarProjectPreview.gif'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:false,
}

export const homeObjFive = {
    id: 'pr',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Project #4',
    headline: 'Old Portfolio',
    description: 'My old portfolio built using HTML5, SCSS/SASS, React.js, React Animejs and React-scroll-parallax',
    buttonLabel: 'Visit Project',
    projectLink: 'https://rakosha.github.io/hryhorii-pertaia-portfolio-gh-pages/',
    imgStart: false,
    img: require('../../images/old-port-img.png'),
    alt:'Car',
    dark:false,
    primary:false,
    darkText:true,
}

export const homeObjSix = {
    id: 'pr-2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Project #6',
    headline: 'Signature Verification: Machine Learning Project',
    description: 'This project was built using Python, scikit-learn, PyTorch, TensorFlow, and Matplotlib.',
    buttonLabel: 'Visit Project',
    projectLink: 'https://github.com/Rakosha/Signature-Verification-Project',
    imgStart: true,
    img: require('../../images/signature-verification-preview.png'),
    alt:'Car',
    dark:true,
    primary:true,
    darkText:false,
}

// export const homeObjSeven = {
//     id: 'pr',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Project #7',
//     headline: 'Materialized Tabs + Animated Background',
//     description: 'Materialized Tabs + Android-like background animation. Both content and background are animated, but content is sliding faster than background.',
//     buttonLabel: 'Visit Project',
//     projectLink: 'https://codepen.io/rakosha/pen/vdyarj',
//     imgStart: false,
//     img: require('../../images/old-port-img.png'),
//     alt:'Car',
//     dark:false,
//     primary:false,
//     darkText:true,
// }